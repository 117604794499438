import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/globals';
import { SubscriptionService } from '../services/subscription.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { PageChangeEvent } from '@progress/kendo-angular-grid';
declare var Swal: any;


@Component({
  selector: 'app-subscription-dept-list',
  templateUrl: './subscription-dept-list.component.html',
  styleUrl: './subscription-dept-list.component.css'
})
export class SubscriptionDeptListComponent {
  // subscriptionList: { total: any; data: any; };
  subscriptionList = {
    data: [],
    total: 0,
  };

  
  duplicate_name: boolean = false;
  dialogOpened = false;
  form: UntypedFormGroup;
  pageSize: number = 10;
  allowUnsort = true;
  skip: number = 0;
  // institute_id:any;
  paginationEntity = {
    institute_id: this.globals.selectedInstitute?.id,
    // institute_id: this.globals.institute_details.institute.id,
    limit: this.pageSize,
    offset: 0,
    search_text:'',
    // searchData: {
    //   status: '',
    //   searchQuery: ''
    // },
    sortOrder: [{
      // field: "display_text",
      // dir: 'asc'
      field: 'created_at',
      dir: 'desc',
    }]
  };
  state = {
    skip: 0,
    take: this.pageSize
  };
  sort: SortDescriptor[] = [{
    field: 'created_at',
    dir: 'desc',
  }];
  gridLoading:boolean = false;
  discountType: any;

  availableLicense: any;
  subsValidationErr : any;
  used_licenses:any; total_licenses:any;
  subscriptionNumber: any;
  unassignButtonDisabled: any;
  id: any;
  negativeValErr: any;

  constructor(
    public globals: Globals, 
    private router: Router, 
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  institute_id:any

  
  ngOnInit(): void {
    
    this.globals.breadcrumbs = ['Manage','Types',''];

    this.institute_id =
        this.globals.selected_institute != null
          ? this.globals.selected_institute.id
          : null;
      if (this.institute_id == null) {
        this.globals.isLoading = false;
        this.globals.dialogOpened = true;
        //$('#switch_org_modal').modal('show');
      }else{
        this.gridLoading = true;
        this.getData();

      }
  }

  getData() {
    // let entity = {institute_id: this.globals.selectedInstitute.id};
    
    this.subscriptionService.getAllSubDeptList(this.paginationEntity)
      .then((data: any) => {
        this.subscriptionList = {
          data: data.count > 0 ? orderBy(data.data, this.sort) : [],
          total: data.count,
        }
        //console.log(this.subscriptionList)

        // this.subscriptionList = {
        //   data: orderBy(data.data, this.sort),
        //   total: 2,
        // }
        this.gridLoading = false;
      },
      (error) => {
        this.gridLoading = false;
        this.skip = this.skip > this.subscriptionList.total ? 0 : this.skip;
        this.globals.errorSweetAlert();
      });
  }

  subscriptionValidationErr(){

    let currentValue = this.subscriptionNumber
    let availableLicense = this.total_licenses - this.used_licenses;
    if(currentValue> availableLicense){
     this.subsValidationErr=true
     this.unassignButtonDisabled = true;
    }
    else{
     this.subsValidationErr =false
     this.unassignButtonDisabled = false;
 
    }
    if(this.subscriptionNumber < 0){
      this.negativeValErr = true;
     }else{
      this.negativeValErr = false;
  
     }
    
     // alert(this.form.controls.subscription_number.value)
   }

   close() {
    this.dialogOpened = false;
  }

   update(entity) {
   
    // this.id = this.form.controls.id;
    // entity.is_active = (entity.is_active)?1:0;
    //console.log(entity, this.id)
    //console.log(entity)
    const avlblLic = this.total_licenses;
    const finalTotal = avlblLic - this.subscriptionNumber
    let entity1 = {
      subscription_number : this.subscriptionNumber
    }
    this.subscriptionService.updateDeptSubs(entity1, this.id)
      .then((data) => {
        this.globals.isLoading = false;
        this.globals.sweetAlert('success','Subscription Updated','Subscription has been updated successfully.');
        this.router.navigate(['/manage/subscription-department/list']);
        this.dialogOpened = false;
        this.getData();
      },
      (error) => {
        this.globals.isLoading = false;
     
          this.globals.errorSweetAlert();
        
      });
  }

  changeStatus(id : any,action){


    Swal.fire({
      icon: 'warning',
      title: action+' Subscription',
      text: 'Are you sure, you want to ' +action.toLowerCase()+ ' this subscription?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.gridLoading = true;
        this.subscriptionService.changeStatusDeptSubscription(id)
        .then((data) => {
          this.globals.isLoading = false;
          this.gridLoading = false;
          this.getData();
          this.globals.sweetAlert(
            'success',
            'Subscription '+action+'d',
            'Subscription '+action+'d successfully.'
          );

        },
        (error) => {
          this.globals.isLoading = false;
          this.gridLoading = false;

          this.globals.errorSweetAlert();
        });
      }
    });
 
  }

  getById(id:any){
    // console.log(id)
    this.dialogOpened = true;
      this.globals.isLoading = true;
      this.subscriptionService.getByDeptId(id)
      .then((data) => {
        this.globals.isLoading = false;
        var fields = data['data'];
        // this.form.patchValue({    
        //   subscription_number: fields['total_licenses'],
        // });
        this.subscriptionNumber =  fields['total_licenses'];
        this.used_licenses = fields.used_licenses;
        this.total_licenses= fields.total_licenses;
        this.id = fields.id;
        //console.log(fields)

        
        let avlblLicence = this.total_licenses - this.used_licenses
        this.availableLicense = avlblLicence;
        if(this.subscriptionNumber > avlblLicence){
          this.unassignButtonDisabled = true;
        }
        // this.subscriptionValidationErr()
      },
      (error) => {
        this.globals.isLoading = false;
        this.globals.errorSweetAlert();
      });
  }


  public pageChange(event: PageChangeEvent): void {
    this.skip = (this.pageSize == event.take) ? event.skip : ((this.skip < event.take) ? event.skip : this.skip);
    this.pageSize = event.take;
    this.paginationEntity.offset = Math.floor((this.skip / this.pageSize));
    this.paginationEntity.limit = event.take;
    this.getData();
  }

  // sortOrder change Event
  public sortChange(sort: any): void {
    if (sort.dir != "undefined") {
      this.sort = sort;
      this.paginationEntity.sortOrder = [];
      this.paginationEntity.sortOrder = sort;
      this.getData();
    }
  }

  // Filter event
  public onFilter(inputValue: string): void {
    if (inputValue != "" && inputValue.length>=1) {
      this.paginationEntity.offset = 0;
      this.paginationEntity.search_text = inputValue;
      // this.paginationEntity.searchData.searchQuery = inputValue;
      this.getData();
    }  else if(inputValue == "") {
      // this.paginationEntity.searchData.searchQuery = '';
      this.paginationEntity.search_text='';
      this.pageChange(this.state);
    }
  }
}
